import React, { useContext } from "react"
import styled from "styled-components"
import { withPrefix } from "gatsby"
import loadable from "@loadable/component"
import Hero from "../components/hero"
import Wrapper from "../components/wrapper"
import SEO from "../components/SEO"
import { GlobalStateContext } from "../components/context"

const Layout = loadable(() => import("../components/layout"))

const StyledWrapper = styled(Wrapper)`
  background-image: url("https://source.unsplash.com/1280x720/?space,dark");
  height: 30rem;
  border-bottom: none;
`

const MainTitle = styled.h1`
  line-height: 1.5;
  text-align: center;
  font-size: 3rem;
  color: white;
`

const Text = styled.p`
  text-align: center;
  color: white;
`

const NotFoundPage = ({ location }) => {
  const state = useContext(GlobalStateContext)
  return (
    <>
      <SEO title="Page Not Found" />
      <Layout location={location} noCover={true}>
        <Hero heroImg={withPrefix("/images/404.jpeg")} title="404" />
        <StyledWrapper>
          <MainTitle>
            {state.language === "ptBR"
              ? "404 Página não encontrada"
              : "404 Page Not Found"}
          </MainTitle>
          <Text>
            {state.language === "ptBR"
              ? "Parece que você clicou em um link quebrado ou tentou acessar uma URL que não existe nesse site."
              : "Looks like you've followed a broken link or entered a URL that doesn't exist on this site."}
          </Text>
        </StyledWrapper>
      </Layout>
    </>
  )
}

export default NotFoundPage
